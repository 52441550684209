import React, { useState } from "react"
import styled from "styled-components"
import Box from "@material-ui/core/Box"
import Grid from "./Grid"
import Typography from "@material-ui/core/Typography"
import ResourceCard from "./ResourceCard"
import Wrapper from "./layout/Wrapper"
import Button from "./Button"

export default function ArticleContainer({ data, tag, description }) {
  const [maxNumberOfArticles, setMaxNumberOfArticles] = useState(7)
  const loadMore = 12
  const lengthOfArticles = data.length
  return (
    <Box py={8}>
      <Wrapper>
        {data.length > 0 && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <ResourceCard data={data[0].node} tag={tag} size="lg" />
            </Grid>
            {tag && (
              <Grid item container spacing={4}>
                <Grid item container spacing={4}>
                  {data.length > 1 &&
                    data.slice(1, maxNumberOfArticles).map((card, index) => (
                      <Grid xs={12} sm={6} md={4} item key={index}>
                        <ResourceCard data={card.node} tag={tag} size="sm" />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            )}
            {!tag && data.length > 1 && (
              <Box ml={4} mt={8} mb={1}>
                <Typography variant="h2" color="textPrimary">
                  Latest Articles
                </Typography>
              </Box>
            )}
            {!tag && (
              <Grid item container spacing={4}>
                {data.length > 1 && (
                  <Grid item md={6}>
                    <ResourceCard data={data[1]} tag={tag} size="md" />
                  </Grid>
                )}
                <Grid item md={6} container spacing={4}>
                  {data.length > 2 &&
                    data.slice(2, 4).map((card, index) => (
                      <GridXsHeight xs={12} item key={index}>
                        <ResourceCard data={card.node} tag={tag} size="xs" />
                      </GridXsHeight>
                    ))}
                </Grid>
                <Grid item container spacing={4}>
                  {data.length > 5 &&
                    data.slice(4, maxNumberOfArticles).map((card, index) => (
                      <Grid xs={12} sm={6} md={4} item key={index}>
                        <ResourceCard data={card.node} tag={tag} size="sm" />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            )}
            {lengthOfArticles > maxNumberOfArticles && (
              <LoadMore px={2} mt={2}>
                <Button
                  maxWidth
                  variant="contained"
                  colour="light"
                  onClick={() =>
                    setMaxNumberOfArticles(maxNumberOfArticles + loadMore)
                  }
                >
                  Load more
                </Button>
              </LoadMore>
            )}
          </Grid>
        )}
      </Wrapper>
    </Box>
  )
}

const LoadMore = styled(Box)`
  display: flex;
  width: 100%;
`

const GridXsHeight = styled(Grid)`
  ${({ theme }) => `
        ${theme.breakpoints.up("md")} {
            max-height: 33.33%;
        }
    `}
`
