import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { ThemeProvider } from "styled-components"
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles"
import theme from "../styles/theme/index.js"
import GlobalCss from "../styles/theme/global"
import NavbarCustom from "../components/NavbarCustom"
import ArticlesContainer from "../components/ArticlesContainer"
import SEO from "../components/SEO"
import { useMediaQuery } from "../utils/useMediaQuery"
import { websiteLink } from "../utils/constants"

const Resources = ({ data, pageContext, location }) => {
  const showResourcesNav = useMediaQuery("(max-width: 600px)")
  const { allArticles, tagArticles } = data
  const { tag, description, metadata } = pageContext
  const canonicalUrl = websiteLink + location.pathname

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0)
    }
  }, [location])

  return (
    <>
      <SEO data={metadata} canonicalUrl={canonicalUrl} />
      <StylesProvider>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <GlobalCss />
            <Layout hasHero={true && !showResourcesNav}>
              <NavbarCustom resources />
              <NavbarCustom blog />
              <ArticlesContainer
                data={tag ? tagArticles.edges : allArticles.edges}
                tag={tag || true}
                description={description}
              />
            </Layout>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </>
  )
}

export default Resources

export const query = graphql`
  query ResourcesPage($slug: String) {
    site: site {
      siteMetadata {
        siteUrl
      }
    }
    allArticles: allContentfulBlogArticle(
      sort: { fields: [dateOfPublication], order: DESC }
    ) {
      edges {
        node {
          title
          slug
          synopsis {
            synopsis
          }
          tags {
            title
          }
          dateOfPublication(formatString: "DD MMMM YYYY")
          image {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
    }
    tagArticles: allContentfulBlogArticle(
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: dateOfPublication, order: DESC }
    ) {
      edges {
        node {
          title
          slug
          synopsis {
            synopsis
          }
          tags {
            title
          }
          dateOfPublication(formatString: "DD MMMM YYYY")
          image {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
    }
  }
`
